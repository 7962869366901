module.exports = {
  background: '#FFFFFF',
  backgroundDark: '#f0e6f6',

  primary: '#C38D9D',
  primaryLight: '#E27E5E',
  primaryDark: '#E27E5E',

  secondary: '#84cdca',
  secondaryLight: '#84cdca',
  secondaryDark: '#E27E5E',
};
